import { isToday } from 'date-fns'
import { format as formatDate } from 'date-fns'
import { newDateWithoutTime } from 'utils/date'
import {
  FormatDateFromString,
  FormatDateRangeProps,
  FormatOnlyStartDateProps,
  FormatStartAndEndDatesProps,
  GetDateTimeFormatProps,
} from './types'

const FORMAT_TIME = 'HH:mm'
const EMPTY_VALUE = '-'
const MAX_TITLE_LENGTH = 30

export const formatDateFromString = ({ dateString, format, locale }: FormatDateFromString) => {
  if (dateString.trim() === '') return ''

  const date = newDateWithoutTime(dateString)

  return formatDate(date, format, { locale })
}

const getDateTimeFormat = ({ includeTime }: GetDateTimeFormatProps = { includeTime: false }) =>
  `dd. MMM${includeTime ? ', HH:mm' : ''}`

const formatOnlyStartDate = ({ startDate, isWholeDay, t, locale }: FormatOnlyStartDateProps) => {
  // 31. Mai, Ganze tag
  if (isWholeDay) {
    return t('page.cms.content.event.dateRangeFullDay', {
      dateRange: formatDateFromString({ dateString: startDate, format: getDateTimeFormat(), locale }),
    })
  }

  // Heute, 10:30
  if (isToday(startDate)) {
    return t('page.cms.content.event.dateRangeToday', {
      dateRange: formatDateFromString({
        dateString: startDate,
        format: FORMAT_TIME,
        locale,
      }),
    })
  }

  // 31. Mai, 15:30
  return formatDateFromString({
    dateString: startDate,
    format: getDateTimeFormat({ includeTime: true }),
    locale,
  })
}

const formatStartAndEndDates = ({ startDate, endDate, isWholeDay, t, locale }: FormatStartAndEndDatesProps) => {
  // 31. Mai, Ganze tage
  if (isWholeDay) {
    if (startDate === endDate) {
      return `${formatDateFromString({
        dateString: startDate,
        format: getDateTimeFormat(),
        locale,
      })} ${t('page.cms.content.event.dateRangeFullDays', {
        dateRange: '',
      })}`
    }

    // 31. Mai - 13. juni, Ganze tage
    return `${formatDateFromString({
      dateString: startDate,
      format: getDateTimeFormat(),
      locale,
    })} - ${t('page.cms.content.event.dateRangeFullDays', {
      dateRange: formatDateFromString({
        dateString: endDate,
        format: getDateTimeFormat(),
        locale,
      }),
    })}`
  }

  // Heute, 10:30 - 20:30
  if (isToday(startDate) && isToday(endDate)) {
    return `${t('page.cms.content.event.dateRangeToday', {
      dateRange: formatDateFromString({
        dateString: startDate,
        format: FORMAT_TIME,
        locale,
      }),
    })} - ${formatDateFromString({
      dateString: endDate,
      format: FORMAT_TIME,
      locale,
    })}`
  }

  // 31. Mai, 18:00 - 13. juni, 20:00
  return `${formatDateFromString({
    dateString: startDate,
    format: getDateTimeFormat({ includeTime: true }),
    locale,
  })} - ${formatDateFromString({
    dateString: endDate,
    format: getDateTimeFormat({ includeTime: true }),
    locale,
  })}`
}

export const formatDateRange = ({ startDate, endDate, isWholeDay = false, t, locale }: FormatDateRangeProps) => {
  if (!startDate || !locale) return EMPTY_VALUE

  if (!endDate) return formatOnlyStartDate({ startDate, isWholeDay, t, locale })

  return formatStartAndEndDates({
    startDate,
    endDate,
    isWholeDay,
    t,
    locale,
  })
}

export const formatTitle = (text: string) => {
  if (!text || text.length <= MAX_TITLE_LENGTH) return text
  return `${text.substring(0, MAX_TITLE_LENGTH)}...`
}
