import React, { useCallback, SyntheticEvent, useState } from 'react'
import { Box } from '@mui/material'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import TabContent from 'components/TabContent'
import Tabs from 'components/Tabs'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { EVENT_TIMELINE_FILTERS } from 'types/cms'
import palette from 'utils/theme/palette'
import { styles } from '../styles'
import { EventFilterTabs, TabT } from '../types'
import EventTab from './EventTab'

const EventIndexPage: React.FC<RouteComponentProps<{ ownerId: string }>> = ({ match }) => {
  const { t } = useFavurTranslation()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const history = useHistory()
  const [tabIndex, setTabIndex] = useState(1)
  const {
    params: { ownerId },
  } = match

  const owner = parseInt(ownerId)

  const gotoBackLink = useCallback(() => {
    pushBackLinkOrDefault(() => {
      history.goBack()
    })
  }, [pushBackLinkOrDefault, history])

  const handleChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: number) => {
      if (newValue !== tabIndex) {
        setTabIndex(newValue)
      }
    },
    [tabIndex],
  )

  return (
    <Page
      backgroundColor={palette.primary[15]}
      header={
        <WithBackwardLink
          onClick={gotoBackLink}
          title={t('page.cms.event.header')}
          iconSx={styles.subHeaderIcon}
          iconThin
        />
      }
      sxHeader={styles.subHeader}
    >
      <Tabs
        currentTabIndex={tabIndex}
        handleChange={handleChange}
        removeTopContentPadding
        tabs={
          [
            {
              id: 0,
              label: (
                <Box sx={styles.tabLabel}>
                  <span>{t('page.cms.event.filterPast')}</span>
                </Box>
              ),
            },
            {
              id: 1,
              label: (
                <Box sx={styles.tabLabel}>
                  <span>{t('page.cms.event.filterUpcoming')}</span>
                </Box>
              ),
            },
          ] as TabT[]
        }
      >
        <TabContent index={EventFilterTabs.past} currentTabIndex={tabIndex}>
          <EventTab eventTimelineFilter={EVENT_TIMELINE_FILTERS.PAST} ownerId={owner} />
        </TabContent>
        <TabContent index={EventFilterTabs.upcoming} currentTabIndex={tabIndex}>
          <EventTab eventTimelineFilter={EVENT_TIMELINE_FILTERS.UPCOMING} ownerId={owner} />
        </TabContent>
      </Tabs>
    </Page>
  )
}

export default EventIndexPage
