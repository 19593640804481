import { useContext } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import FlashMessagesContext from 'services/FlashMessages/context'
import { CmsContentStatsT } from 'types/cms'
import { ApolloError } from '@apollo/client'
import { getCmsContentStatsQuery } from './queries'

const useGetCmsContent = () => {
  const { t } = useFavurTranslation()
  const { setFlashMessage } = useContext(FlashMessagesContext)

  const onError = (error: ApolloError) => {
    // code is coming from the backend as part of the error but is not being recognized as an apollo error prop
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((error.graphQLErrors[0] as any).code === 'error.be.cms.fetchingContent') {
      setFlashMessage(t('page.cms.content.error.be.fetchingContent'))
    } else setFlashMessage(error.message)
  }
  const { data: data, loading: loading } = useJamesApolloQuery<{ cmsContentStats: CmsContentStatsT[] }>(
    getCmsContentStatsQuery,
    {
      fetchPolicy: 'cache-and-network',
      onError: onError,
    },
  )

  return {
    stats: data?.cmsContentStats ? data.cmsContentStats : [],
    loading,
  }
}

export default useGetCmsContent
