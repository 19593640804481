import React from 'react'
import { AuthenticationKind } from 'constants/authentication'
import usePackage from 'hooks/usePackage'
import useRolesViews from 'hooks/useRolesViews'
import useSessionContext from 'hooks/useSessionContext'
import BalancesPage from 'pages/Balances'
import ContactPage from 'pages/Contact'
import DashboardPage from 'pages/Dashboard'
import DocumentsPage from 'pages/Documents'
import InvitationPage from 'pages/EntryPoint/InvitationPage'
import LoginPage from 'pages/EntryPoint/LoginPage'
import TermsAndConditionsUpdatePage from 'pages/EntryPoint/LoginPage/TermsAndConditionsUpdate'
import ForgotPhonePage from 'pages/ForgotPhone'
import AppTeaserPage from 'pages/NativeAppTeaser'
import NotificationsPage from 'pages/Notifications'
import PhoneChangePage from 'pages/PhoneChange'
import SecuritySetupPage from 'pages/SecuritySetup/InitialSetup'
import PinChangePage from 'pages/SecuritySetup/PinChange'
import PinResetPage from 'pages/SecuritySetup/PinReset'
import SettingsPage from 'pages/Settings'
import ShiftsPage from 'pages/Shifts'
import MonthlySheetPage from 'pages/Tasks/MonthlySheet'
import SecureConnectionDetails from 'pages/Tasks/SecureConnection'
import EmployersSelectionPage from 'pages/Tasks/ShareUserData/EmployersSelectionPage'
import TutorialPage from 'pages/Tutorial'
import UserSettingsPage from 'pages/UserSettings'
import DeactivateAccount from 'pages/UserSettings/pages/DeactivateAccount'
import ConfirmDeactivateAccount from 'pages/UserSettings/pages/DeactivateAccount/Confirm'
import SuccessDeleteUserPage from 'pages/UserSettings/pages/DeactivateAccount/Confirm/SuccessDeleteUserPage'
import EmployerPage from 'pages/UserSettings/pages/Employer'
import EmployersPage from 'pages/UserSettings/pages/Employers'
import EmploymentDataPage from 'pages/UserSettings/pages/EmploymentData'
import ForcePinLogin from 'pages/UserSettings/pages/ForcePinLogin'
import NotificationSettingsPage from 'pages/UserSettings/pages/Notifications'
import PersonalDataPage from 'pages/UserSettings/pages/PersonalData'
import ProfilePage from 'pages/UserSettings/pages/Profile'
import EmployerPersonTeamPage from 'pages/UserSettings/pages/Team'
import EmployerPersonTeamsPage from 'pages/UserSettings/pages/Teams'
import { Redirect } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from 'utils/ProtectedRoute'
import { isNative } from 'utils/platform'
import {
  absencePlannerRoutes,
  classicPackageRoutes,
  cmsRoutes,
  officeRoutes,
  routesWithAbsenceWorkflow,
  teamRoutes,
  tenantRoutes,
} from './conditionalRoutes'
import routes from './routes'
import { isCmsRedirect } from './utils'

interface IGetConditionalRoutesProps {
  hasActivePersons: boolean
  isAuthenticated: boolean
  hasClassicLightAccess: boolean
  hasClassicAccess: boolean
  canManageTeams: boolean
  canManageTenants: boolean
  hasAbsenceBetaWorkflow: boolean
  hasOfficeView: boolean
  cmsActivated: boolean
}

const getConditionalRoutes = ({
  hasActivePersons,
  isAuthenticated,
  hasClassicLightAccess,
  hasClassicAccess,
  canManageTeams,
  canManageTenants,
  hasAbsenceBetaWorkflow,
  hasOfficeView,
  cmsActivated,
}: IGetConditionalRoutesProps) => [
  ...(hasActivePersons && hasClassicLightAccess ? absencePlannerRoutes(isAuthenticated, hasClassicAccess) : []),
  ...(hasClassicLightAccess ? classicPackageRoutes(isAuthenticated) : []),
  ...(canManageTeams ? teamRoutes(isAuthenticated) : []),
  ...(canManageTenants ? tenantRoutes(isAuthenticated) : []),
  ...(hasAbsenceBetaWorkflow ? routesWithAbsenceWorkflow(isAuthenticated) : []),
  ...(hasOfficeView ? officeRoutes(isAuthenticated) : []),
  ...(cmsActivated ? cmsRoutes(isAuthenticated, hasClassicAccess) : []),
]

const RoutesProvider: React.FC = () => {
  const { auth, cmsActivated } = useSessionContext()
  const { hasClassicAccess, hasAbsenceBetaWorkflow, hasClassicLightAccess } = usePackage()
  const { hasOfficeView, canManageTeams, hasActivePersons, canManageTenants } = useRolesViews()
  const queryParams = new URLSearchParams(location.search)

  if (![AuthenticationKind.AUTHENTICATED, AuthenticationKind.ANONYMOUS].includes(auth)) {
    return <Route component={() => null} />
  }
  const isAuthenticated = auth > AuthenticationKind.ANONYMOUS

  return (
    <Switch>
      <ProtectedRoute
        path={`${routes.login}/:step?`}
        // @ts-ignore
        component={LoginPage}
        isAllowed={!isAuthenticated}
        redirectTo={routes.dashboard}
        redirectLogin={isCmsRedirect(queryParams)}
        noPushRegistration
      />
      <ProtectedRoute
        path={`${routes.forgotPhone}/:step?`}
        // @ts-ignore
        component={ForgotPhonePage}
        isAllowed={!isAuthenticated}
        redirectTo={routes.dashboard}
        noPushRegistration
      />
      <Route
        path={`${routes.invite}/:inviteCode`}
        // @ts-ignore
        component={InvitationPage}
        exact
      />
      <Route
        path={routes.settings}
        // @ts-ignore
        component={SettingsPage}
        exact
      />
      <Route
        path={routes.contact}
        // @ts-ignore
        component={ContactPage}
        exact
      />
      <Route
        path={routes.deleteAccountSuccess}
        // @ts-ignore
        component={SuccessDeleteUserPage}
        exact
      />
      <ProtectedRoute
        path={routes.documents}
        // @ts-ignore
        component={DocumentsPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.dashboard}
        // @ts-ignore
        component={DashboardPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        key={routes.tutorial}
        path={`${routes.tutorial}/:tutorialName`}
        // @ts-ignore
        component={TutorialPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.shifts}
        // @ts-ignore
        component={ShiftsPage}
        exact
        isAllowed={isAuthenticated}
        classicModuleAccess={hasClassicAccess}
      />

      <ProtectedRoute
        path={routes.appTeaser}
        // @ts-ignore
        component={AppTeaserPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.termsAndConditionsUpdate}
        // @ts-ignore
        component={TermsAndConditionsUpdatePage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.balances}
        // @ts-ignore
        component={BalancesPage}
        exact
        isAllowed={isAuthenticated}
        classicModuleAccess={hasClassicLightAccess}
      />
      <ProtectedRoute
        path={`${routes.monthlySheets}/:taskUuid`}
        // @ts-ignore
        component={MonthlySheetPage}
        exact
        isAllowed={isAuthenticated}
        classicModuleAccess={hasClassicLightAccess}
      />
      <ProtectedRoute
        path={routes.userSettings}
        // @ts-ignore
        component={UserSettingsPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.profile}
        // @ts-ignore
        component={ProfilePage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.profileForcePinLogin}
        // @ts-ignore
        component={ForcePinLogin}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.profileDeactivateAccount}
        // @ts-ignore
        component={DeactivateAccount}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.profileConfirmDeactivateAccount}
        // @ts-ignore
        component={ConfirmDeactivateAccount}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={`${routes.employmentDataShare}`}
        // @ts-ignore
        component={EmployersSelectionPage}
        exact
        isAllowed={isAuthenticated}
      />
      {hasClassicLightAccess ? (
        <ProtectedRoute
          path={`${routes.employmentData}/:tab?`}
          // @ts-ignore
          component={EmploymentDataPage}
          exact
          isAllowed={isAuthenticated}
        />
      ) : (
        <ProtectedRoute
          path={routes.employmentData}
          // @ts-ignore
          component={PersonalDataPage}
          exact
          isAllowed={isAuthenticated}
        />
      )}
      <ProtectedRoute
        path={routes.employers}
        // @ts-ignore
        component={EmployersPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={`${routes.employer}/:favurUuid`}
        // @ts-ignore
        component={EmployerPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={`${routes.employers}/:favurUuid/teams`}
        // @ts-ignore
        component={EmployerPersonTeamsPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={`${routes.employers}/:favurUuid/teams/:teamId`}
        // @ts-ignore
        component={EmployerPersonTeamPage}
        exact
        isAllowed={isAuthenticated}
      />
      {isNative() && (
        <ProtectedRoute
          path={routes.notificationsSettings}
          // @ts-ignore
          component={NotificationSettingsPage}
          exact
          isAllowed={isAuthenticated}
        />
      )}
      <ProtectedRoute
        path={`${routes.phoneChange}/:step?`}
        // @ts-ignore
        component={PhoneChangePage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.notifications}
        // @ts-ignore
        component={NotificationsPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={`${routes.securitySetup}/:step?`}
        // @ts-ignore
        component={SecuritySetupPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={`${routes.pinReset}/:step?`}
        // @ts-ignore
        component={PinResetPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={`${routes.pinChange}/:step?`}
        // @ts-ignore
        component={PinChangePage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={`${routes.secureConnection}/:favurUuid`}
        // @ts-ignore
        component={SecureConnectionDetails}
        exact
        isAllowed={isAuthenticated}
      />

      {getConditionalRoutes({
        hasActivePersons,
        isAuthenticated,
        hasClassicAccess,
        hasClassicLightAccess,
        canManageTeams,
        canManageTenants,
        hasAbsenceBetaWorkflow,
        hasOfficeView,
        cmsActivated,
      })}

      {auth < AuthenticationKind.AUTHENTICATED ? (
        <Redirect from="*" to={routes.login} />
      ) : (
        <Redirect from="*" to={routes.dashboard} />
      )}
    </Switch>
  )
}

export default RoutesProvider
