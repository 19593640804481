import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventM } from 'icons'
import CompanyPagesM from 'icons/CompanyPagesM'
import NewsletterM from 'icons/NewsletterM'
import { formatDateFromString } from 'pages/Cms/EventsList/EventCard/utils'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import palette from 'utils/theme/palette'
import Section from '../components/Section'
import SectionCTA from '../components/SectionCTA'
import { ICompanyOverviewProps } from './types'

const DATE_FORMAT = `dd. MMM yyyy`
const CompanyOverview: React.FC<ICompanyOverviewProps> = ({ title, subtitle, ownerStats }) => {
  const { t, locale } = useFavurTranslation()
  const history = useHistory()

  return (
    <Section title={title} subtitle={subtitle}>
      <SectionCTA
        title={t('page.cms.overview.events.title')}
        subtitle={t('page.cms.overview.events.subText', {
          number: ownerStats?.events?.upcoming ? ownerStats?.events?.upcoming : 0,
        })}
        onClick={() => history.push(routes.cmsEventIndexOwner(ownerStats?.ownerId))}
        icon={<EventM fill={palette.primary.main} />}
      />
      <SectionCTA
        title={t('page.cms.overview.pages.title')}
        onClick={() => history.push(routes.cmsContentIndex)}
        icon={<CompanyPagesM stroke={palette.primary.main} />}
      />
      <SectionCTA
        title={t('page.cms.overview.newsletter.title')}
        subtitle={t('page.cms.overview.newsletter.subText', {
          date: ownerStats?.newsletters?.latestSent
            ? formatDateFromString({ dateString: ownerStats?.newsletters?.latestSent, format: DATE_FORMAT, locale })
            : '',
        })}
        onClick={() => history.push(routes.cmsOverview)}
        icon={<NewsletterM fill={palette.primary.main} />}
      />
    </Section>
  )
}

export default CompanyOverview
