import { TeamTabs } from 'pages/Teams/TeamPage/constants'

export const basicRoutes = {
  appTeaser: '/app-teaser',
  closedTasks: '/tasks/closed',
  contact: '/contact',
  dashboard: '/me',
  deleteAccountSuccess: '/profile/delete-account/success',
  documents: '/documents',
  employer: '/user-settings/employer',
  employers: '/user-settings/employers',
  employersTeams: (personFavurUuid: string) => `/user-settings/employers/${personFavurUuid}/teams`,
  employersTeam: (personFavurUuid: string, teamId: string) =>
    `/user-settings/employers/${personFavurUuid}/teams/${teamId}`,
  employmentData: '/user-settings/employment-data',
  employmentDataShare: '/user-settings/share/employment-data',
  forgotPhone: '/forgot-phone',
  invite: '/invite',
  login: '/sign-in',
  notifications: '/notifications',
  notificationsSettings: '/user-settings/notifications',
  openTasks: '/tasks/open',
  pendingTasks: '/tasks/pending',
  pinChange: '/pin-change',
  pinReset: '/pin-reset',
  phoneChange: '/phone-change',
  profile: '/profile',
  profileDeactivateAccount: '/profile/deactivate-account',
  profileConfirmDeactivateAccount: '/profile/confirm-deactivate-account',
  profileForcePinLogin: '/profile/force-pin-login',
  securitySetup: '/security-setup',
  settings: '/settings',
  requestEmployeeData: '/requestEmployeeData',
  termsAndConditionsUpdate: '/terms-conditions-update',
  tasks: '/tasks',
  teamDetailMember: `${TeamTabs.members}/detail`,
  teamEditMembers: `${TeamTabs.members}/edit`,
  teamEditPermissionMembers: `${TeamTabs.rights}/edit`,
  teamEditSettings: `${TeamTabs.settings}/edit`,
  teamOwnerMembers: (ownerId: number, status: string) => `/teams/owner/${ownerId}/members/status/${status}`,
  teamOwnerMemberDetail: (ownerId: string, personFavurUuid: string) =>
    `/teams/owner/${ownerId}/members/${personFavurUuid}`,
  teamsWithOwner: (ownerId: string) => `/teams/owner/${ownerId}`,
  tenant: '/user-settings/tenant',
  tenantReminders: (tenantUuid: string) => `/user-settings/tenant/${tenantUuid}/reminders`,
  tenantRemindersList: (tenantUuid: string, type: string) => `/user-settings/tenant/${tenantUuid}/reminders/${type}`,
  tenants: '/user-settings/tenants',
  tenantWithUuid: (tenantUuid: string) => `/user-settings/tenant/${tenantUuid}`,
  teams: '/teams',
  tutorial: '/tutorial',
  userSettings: '/user-settings',
  secureConnection: '/secure-connection',
  secureConnectionWithTask: (taskUuid: string) => `/secure-connection/${taskUuid}`,
}

export const classicRoutes = {
  balances: '/balances',
  monthlySheets: '/time-approval',
  monthlySheetsWithTask: (taskUuid: string) => `/time-approval/${taskUuid}`,
  shifts: '/shifts',
  userDataDetails: '/share-personal-data',
  absencePlanner: '/absence-planner',
  employeeCard: '/employee-card',
}

export const absenceBetaWorkflowRoutes = {
  absenceRequestDetail: '/absence-request/detail',
  approveAbsenceRequest: '/absence-request/approve',
  approveAbsenceRequestDetail: '/absence-request/approve/detail',
  closedAbsenceRequest: '/absence-request/closed',
  newAbsenceRequest: '/absence-request/new',
  rejectAbsenceRequest: '/absence-request/reject',
}

export const shiftUpdateWorkflowRoutes = {
  shiftUpdateDetail: '/shift-update/detail',
}

export const cmsRoutes = {
  cmsContentView: '/cms-content/view',
  cmsContentIndex: '/cms-content',
  cmsEventIndex: '/cms-event',
  cmsEventIndexOwner: (ownerId?: number) => `/cms-event/${ownerId}`,
  cmsOverview: '/cms-overview',
  cmsRedirect: '/cms-redirect',
}

export const workflows = {
  ...absenceBetaWorkflowRoutes,
  ...shiftUpdateWorkflowRoutes,
}

const routes = {
  ...basicRoutes,
  ...classicRoutes,
  ...cmsRoutes,
  ...workflows,
}

export default routes
