import React, { useCallback, useMemo, useState } from 'react'
import BackwardLink from 'components/BackwardLink'
import useFilterUtils from 'components/Filter/useFilterUtils'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useRolesViews from 'hooks/useRolesViews'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { taskQueryApollo } from '../queries'
import { headerClasses } from '../styles'
import { SecureConnectionTaskT, TaskT } from '../types'

const SecureConnectionDetails: React.FC<RouteComponentProps<{ favurUuid: string }>> = ({ match }) => {
  const {
    params: { favurUuid },
  } = match
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { activeView } = useRolesViews()
  const { setLoadFilterTasks } = useFilterUtils()
  const isOffice = useMemo(() => activeView === ROLE_VIEWS.office, [activeView])
  const [task, setTask] = useState<TaskT<SecureConnectionTaskT>>()

  const { loading } = useJamesApolloQuery<{ task: TaskT<SecureConnectionTaskT> }>(taskQueryApollo, {
    fetchPolicy: 'no-cache',
    variables: { favurUuid, office: isOffice },
    onCompleted: (data) => {
      const { task: queriedTask } = data
      setTask(queriedTask)
    },
    onError() {
      history.push(routes.dashboard)
    },
  })

  const gotoBackLink = useCallback(() => {
    setLoadFilterTasks()
    history.goBack()
  }, [history, setLoadFilterTasks])

  return (
    <Page
      hideNativeNavigation
      hideHamburger
      hideBackground
      removeLastMargin
      header={<BackwardLink onClickIcon={gotoBackLink} title={t('tasks.secureConnection.details.title')} />}
      sxHeader={headerClasses.subHeader}
    >
      {loading || !task ? <SimpleLoading /> : <>content goes here</>}
    </Page>
  )
}

export default SecureConnectionDetails
