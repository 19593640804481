import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { OwnerPersons, useOwnersOfUser } from 'hooks/useOwnersOfUser'
import CompanyOverview from './CompanyOverview'
import useGetCmsContentStats from './useGetCmsContentStats'

const PageList = () => {
  const { t } = useFavurTranslation()
  const { stats } = useGetCmsContentStats()
  const { owners } = useOwnersOfUser({})

  return (
    <div>
      {owners.map((personOwner: OwnerPersons) => {
        return (
          <CompanyOverview
            key={personOwner.owner.id}
            title={personOwner.owner.name}
            ownerStats={stats.find((ownerStats) => ownerStats.ownerId === personOwner.owner.id)}
            subtitle={t('page.cms.overview.owner.subText')}
          />
        )
      })}
    </div>
  )
}

export default PageList
